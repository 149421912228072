// tslint:disable: indent
export class HttpMapping {


	static LOGIN_URL = '/login';
	static TRANSLATION_POST = '/translate';
	static LIBRETRANSLATE_URL = 'http://localhost:7001/cors';

	static LOCALE_LANGUAGE_GET_ALL = '/localeLanguages';
	static LOCALE_LANGUAGE_GET_BY_ID = '/localeLanguages/{id}';

	static LOCALE_LANGUAGE_POST = '/localeLanguages/create';
	static LOCALE_LANGUAGE_PUT_BY_ID = '/localeLanguages/update/{id}';
	static LOCALE_LANGUAGE_DELETE_BY_ID = '/localeLanguages/delete/{id}';


	/**
	 * Organization REST Mapping
	 */
	static ORGANIZATION_GET_ALL = '/organizations';
	static ORGANIZATION_GET_BY_ID = '/organizations/{id}';

	static ORGANIZATION_POST = '/organizations/create';
	static ORGANIZATION_PUT_BY_ID = '/organizations/update/{id}';
	static ORGANIZATION_DELETE_BY_ID = '/organizations/delete/{id}';

	/**
	 * Role REST Mapping
	 */
	static ROLE_GET_ALL = '/roles';
	static ROLE_GET_BY_ID = '/roles/{id}';

	static ROLE_POST = '/roles/create';
	static ROLE_PUT_BY_ID = '/roles/update/{id}';
	static ROLE_DELETE_BY_ID = '/roles/delete/{id}';



	/**
	 * State REST Mapping
	 */
	static STATE_GET_ALL = '/states';
	static STATE_GET_BY_ID = '/states/{id}';

	static STATE_POST = '/states/create';
	static STATE_PUT_BY_ID = '/states/update/{id}';
	static STATE_DELETE_BY_ID = '/states/delete/{id}';



	/**
	 * User REST Mapping
	 */
	static USER_GET_AUTHENTICATED = '/users/authenticated';
	static USER_GET_ALL = '/users';
	static USER_GET_BY_ID = '/users/{id}';
	static USER_GET_BY_USERNAME = '/users/username/{username}';
	static USER_GET_BY_EMAIL = '/users/email/{email}';
	static USER_GET_CHECK_EMAIL = '/users/email/check/{email}';
	static USER_GET_PASSWD_RESET_CODE = '/users/forgotpasswd/code/{email}';
	static USER_GET_PASSWD_RESET_PROCEED = '/users/forgotpasswd/change/{email}/{newpassword}';

	static USER_POST = '/users/create';
	static USER_POST_SIGNUP = '/users/signup/{passwd}';
	static USER_PUT_BY_ID = '/users/update/{id}';

	static USER_PUT_RESET_PASSWD_BY_ID = '/users/resetpasswd/{id}/{newpassword}';
	static USER_PUT_PASS_BY_ID = '/users/cc/{id}/{currentpassword}/{newpassword}';
	static USER_PUT_PASS_BY_USERNAME = '/users/cc/{username}/{currentpassword}/{newpassword}';
	static USER_PUT_LOCALE_BY_USERNAME = '/users/profile/locale/{username}/{locale}';
	static USER_PUT_THEME_BY_USERNAME = '/users/profile/theme/{username}/{theme}';
	static USER_DELETE_BY_ID = '/users/delete/{id}';
	static USER_GRANT_ROLE = '/users/grant/{userId}/{roleIds}';
	static USER_REVOKE_ROLE = '/users/revoke/{userId}/{roleIds}';

	/**
	 * MinReportingArea REST Mapping
	 */
	static MINREPORTING_GET_ALL = '/minReportings';
	static MINREPORTING_GET_BY_ID = '/minReportings/{id}';

	static MINREPORTING_POST = '/minReportings/create';
	static MINREPORTING_PUT_BY_ID = '/minReportings/update/{id}';
	static MINREPORTING_DELETE_BY_ID = '/minReportings/delete/{id}';


	/**
	 * TechnicalArea REST Mapping
	 */
	static TECHNICAL_AREA_GET_ALL = '/technicalAreas';
	static TECHNICAL_AREA_GET_BY_ID = '/technicalAreas/{id}';

	static TECHNICAL_AREA_POST = '/technicalAreas/create';
	static TECHNICAL_AREA_PUT_BY_ID = '/technicalAreas/update/{id}';
	static TECHNICAL_AREA_DELETE_BY_ID = '/technicalAreas/delete/{id}';


	/**
	 * Deficiency REST Mapping
	 */

	static DEFICIENCY_CLEAR_ALL = '/deficiencies/clear/all';

	static DEFICIENCY_PUT_EXPERT_ANALYSIS_BY_ID = '/deficiencies/expert/analysis/{id}';
	static DEFICIENCY_PUT_COMMITTEE_REVIEW_BY_ID = '/deficiencies/committee/review/{id}';
	static DEFICIENCY_PUT_PIRG_REVIEW_BY_ID = '/deficiencies/pirg/review/{id}';

	static DEFICIENCY_GET_ALL = '/deficiencies';
	static DEFICIENCY_GET_BY_ID = '/deficiencies/{id}';
	static DEFICIENCY_POST_FILTER = '/deficiencies/filter';
	static DEFICIENCY_COUNT_PENDING = '/deficiencies/pendings/count';

	static DEFICIENCY_POST = '/deficiencies/create';
	static DEFICIENCY_POST_REPORT = '/deficiencies/report';
	static DEFICIENCY_PUT_BY_ID = '/deficiencies/update/{id}';
	static DEFICIENCY_DELETE_BY_ID = '/deficiencies/delete/{id}';
	static DEFICIENCY_VALIDATE_BY_ID = '/deficiencies/validate/{id}';
	static DEFICIENCY_CLOSE_BY_ID = '/deficiencies/close/{id}';

	static DEFICIENCY_ADD_CORRECTIVE_ACTION_BY_ID = '/deficiencies/correctiveActions/add/{deficiencyId}';
	static DEFICIENCY_REMOVE_CORRECTIVE_ACTION_BY_ID = '/deficiencies/correctiveActions/remove/{deficiencyId}/{actionId}';

	static DEFICIENCY_RESOURCE_GET_BY_FILE_NAME = '/deficiencies/resources/{deficiencyId}/{fileName}';
	static DEFICIENCY_ADD_RESOURCE_BY_ID = '/deficiencies/resources/add/{deficiencyId}/{fileDescription}';
	static DEFICIENCY_REMOVE_RESOURCE_BY_FILE_NAME = '/deficiencies/resources/remove/{deficiencyId}/{fileName}';

	static DEFICIENCY_REQUEST_CLOSING_BY_ID = '/deficiencies/request/closing/{id}';
	static DEFICIENCY_PROCEED_CLOSED_BY_ID = '/deficiencies/proceed/closed/{id}';

	static DEFICIENCY_REQUEST_VALIDATION_BY_ID = '/deficiencies/request/validation/{id}';
	static DEFICIENCY_PROCEED_VALIDATED_BY_ID = '/deficiencies/proceed/validated/{id}';

	static DEFICIENCY_REQUEST_APPROVAL_BY_ID = '/deficiencies/request/approval/{id}';
	static DEFICIENCY_PROCEED_APPROVED_BY_ID = '/deficiencies/proceed/approved/{id}';


	// deficiency processing
	static DEFICIENCY_PROCESSING_REJECT_BY_ID = '/deficiencies/processing/reject/{id}';
	static DEFICIENCY_PROCESSING_REACTIVATE_BY_ID = '/deficiencies/processing/reactivate/{id}';
	static DEFICIENCY_PROCESSING_CONFIRM_RO_BY_ID = '/deficiencies/processing/confirm/ro/{id}';
	static DEFICIENCY_PROCESSING_SUBMIT_RO_CAP_BY_ID = '/deficiencies/processing/submit/ro/cap/{id}';
	static DEFICIENCY_PROCESSING_REVIEW_RO_CAP_BY_ID = '/deficiencies/processing/review/ro/cap/{id}';
	static DEFICIENCY_PROCESSING_VALIDATE_RO_CAP_BY_ID = '/deficiencies/processing/validate/ro/cap/{id}';
	static DEFICIENCY_PROCESSING_IMPLEMENT_RO_CAP_BY_ID = '/deficiencies/processing/implement/ro/cap/{id}';
	static DEFICIENCY_PROCESSING_CLOSE_RO_BY_ID = '/deficiencies/processing/close/ro/{id}';
	static DEFICIENCY_PROCESSING_UNRESOLVED_RO_BY_ID = '/deficiencies/processing/unresolved/ro/{id}';

	static DEFICIENCY_PROCESSING_REJECT_PIRG_BY_ID = '/deficiencies/processing/reject/pirg/{id}';
	static DEFICIENCY_PROCESSING_CONFIRM_PIRG_BY_ID = '/deficiencies/processing/confirm/pirg/{id}';
	static DEFICIENCY_PROCESSING_SUBMIT_PIRG_CAP_BY_ID = '/deficiencies/processing/submit/pirg/cap/{id}';
	static DEFICIENCY_PROCESSING_REVIEW_PIRG_CAP_BY_ID = '/deficiencies/processing/review/pirg/cap/{id}';
	static DEFICIENCY_PROCESSING_VALIDATE_PIRG_CAP_BY_ID = '/deficiencies/processing/validate/pirg/cap/{id}';
	static DEFICIENCY_PROCESSING_IMPLEMENT_PIRG_CAP_BY_ID = '/deficiencies/processing/implement/pirg/cap/{id}';
	static DEFICIENCY_PROCESSING_CLOSE_PIRG_BY_ID = '/deficiencies/processing/close/pirg/{id}';
	static DEFICIENCY_PROCESSING_REMOVE_BY_ID = '/deficiencies/processing/remove/{id}';

	// deficiency report
	static DEFICIENCY_GENERATE_SUMMARY_REPORT = '/deficiencies/generate/report/summary/{locale}/{reportType}';
	static DEFICIENCY_GENERATE_REPORT_ICAO_BY_ID = '/deficiencies/generate/report-icao/{id}/{locale}';
	static DEFICIENCY_GENERATE_REPORT_STATE_BY_ID = '/deficiencies/generate/report-state/{id}/{locale}';
	static DEFICIENCY_REPORT_TO_PDF = '/deficiencies/report/pdf/{layout}';

	// static DEFICIENCY_CONFIRMATION_LETTER_BY_ID = '/deficiencies/confirmation/letter/{id}/{locale}';
	static DEFICIENCY_COMMITTEE_CONFIRMATION_LETTER_BY_ID = '/deficiencies/committee/confirmation/letter/{id}';
	static DEFICIENCY_PIRG_CONFIRMATION_LETTER_BY_ID = '/deficiencies/pirg/confirmation/letter/{id}';
	static DEFICIENCY_CLOSING_LETTER_BY_ID = '/deficiencies/closing/letter/{id}';

	/**
	 * CorrectiveAction REST Mapping
	 */
	static CORRECTIVE_ACTION_GET_ALL = '/correctiveActions';
	static CORRECTIVE_ACTION_GET_BY_ID = '/correctiveActions/{id}';

	static CORRECTIVE_ACTION_PUT_BY_ID = '/correctiveActions/update/{deficiencyId}/{actionId}';
	static CORRECTIVE_ACTION_COMPLETE_BY_ID = '/correctiveActions/complete/{id}';
	static CORRECTIVE_ACTION_START_BY_ID = '/correctiveActions/start/{id}';


}
