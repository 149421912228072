import { Component, OnInit, Input, Output, ViewChild, ElementRef, AfterViewInit, EventEmitter } from '@angular/core';
import { ReportDocumentPage } from '../../model/report-document-page';
import { ReportDocumentBody } from '../../model/report-document-body';
import { ReportDocumentParagraph } from '../../model/report-document-paragraph';
import { ReportDocumentParagraphText } from '../../model/report-document-paragraph-text';

@Component({
  selector: 'app-document-viewer-page',
  templateUrl: './document-viewer-page.component.html',
  styleUrls: ['./document-viewer-page.component.css']
})
export class DocumentViewerPageComponent implements OnInit, AfterViewInit {

  @Input() reportPage: ReportDocumentPage;
  @Input() size = 'A4';
	@Input() layout = 'portrait';
  @Input() pageIndex: number;
  @Output() remaingPageOut = new EventEmitter<{ index: number, page: ReportDocumentPage }>();
  pageNumberParagraph: ReportDocumentParagraph;
  pageHeight: number;
  pageBodyHeight: number;


  remainingPage: ReportDocumentPage;

  @ViewChild('pageDiv')
  pageDiv: ElementRef;

  constructor() { }

  ngOnInit() {
    this.setPageNumber();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkElementHeight();
    });
  }

  setPageNumber() {
    if (this.reportPage.pageNumber) {
      this.pageNumberParagraph = new ReportDocumentParagraph();
      this.pageNumberParagraph.contentType = 'PARAGRAPH_CONTENT_TEXT';
      this.pageNumberParagraph.contentText = new ReportDocumentParagraphText();
      this.pageNumberParagraph.contentText.content = '~ ' + this.reportPage.pageNumber + ' ~';
      this.pageNumberParagraph.contentText.style = new Map<string, string>();
      this.pageNumberParagraph.contentText.style.set('text-align', 'center');
      this.pageNumberParagraph.style = new Map<string, string>();
      this.pageNumberParagraph.style.set('text-align', 'center');
    }

  }

  checkElementHeight() {
    const div = this.pageDiv.nativeElement as HTMLDivElement;

    // adjust size and layout
    div.setAttribute('size', this.size);
    div.setAttribute('layout', this.layout);

    // calculate page height
    this.pageHeight = div.offsetHeight;
    this.pageBodyHeight = this.pageHeight - 155; // 265;
  }


	/* 	Handle reported remaining page body
	  *  Create remaining page [remainingPage] and add the remaining body
	  * Report the remainingPage to parent page book
  	*/
  handleRemaingBodyOut(eventBody: ReportDocumentBody) {
    if (!this.remainingPage) { this.remainingPage = new ReportDocumentPage(); }
    if (this.reportPage.pageNumber) { this.remainingPage.pageNumber = this.reportPage.pageNumber + 1; }
    this.remainingPage.body = eventBody;
    this.remainingPage.header = this.reportPage.header;
    this.remainingPage.footer = this.reportPage.footer;
    this.remaingPageOut.emit({ index: this.pageIndex, page: this.remainingPage });
  }

}
