import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Deficiency } from '../model/deficiency';
import { Subscription } from 'rxjs';
import { DeficiencyService } from '../deficiency.service';
import { State } from 'src/app/system-administration/model/state';
import { UrlService } from 'src/app/shared/services/url.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { TechnicalArea } from 'src/app/reporting-areas/model/technical-area';
import { ReportingAreasService } from 'src/app/reporting-areas/reporting-areas.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends Translatable implements OnInit, OnDestroy {

  deficiencies: Deficiency[];
  deficienciesSubsciption: Subscription;

  technicalAreas: TechnicalArea[];
  technicalAreasSubsciption: Subscription;

  deficienciesPerArea: { areaCode: string, totalReportedDeficiencies: number, openedDeficiencies: number, resolvedDeficiencies: number }[];
  areaCodes = [];

  lineData: any;

  barData: any;

  deficienciesPerStates: {
    labels: string[],
    datasets: {
      label: string,
      backgroundColor: string,
      borderColor: string,
      data: number[]
    }[]
  };

  deficienciesPerAreaPieData: any;
  pieData: any;

  polarData: any;

  radarData: any;

  chartsOptions: any;

  chartsOptions2: any;

  chartsOptions3: any;

  previousUrl: string;

  barChartOptions: any;


  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    Reported: 'Reported',
    Opened: 'Opened',
    Resolved: 'Resolved',
    DistributionPerStatus: 'Distribution per Status',
    DistributionPerStates: 'Distribution per States',
    TotalRepored: 'Total repored'
  };

  constructor(
    public app: AppComponent,
    public appMain: AppMainComponent,
    public reportingAreasService: ReportingAreasService,
    public deficiencyService: DeficiencyService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'DashboardComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }

  ngOnInit() {
    this.reportingAreasService.loadTechnicalAreas();
    this.deficiencyService.loadDeficiencies();
    this.subscribeToTechnicalAreas();
    this.subscribeToDeficiencies();
    this.subscribeToPreferedLanguage();

    this.chartsOptions = this.getChartOptions();
    this.chartsOptions2 = this.getChartOptions2();
    this.chartsOptions3 = this.getChartOptions3();

    // tslint:disable-next-line: no-string-literal
    this.appMain['refreshChart'] = () => {
      this.chartsOptions = this.getChartOptions();
      this.chartsOptions2 = this.getChartOptions2();
      this.chartsOptions3 = this.getChartOptions3();
    };

    this.initUrl();

  }

  initMenuModel() {
    this.buildDeficienciesPerArea();
    this.buildDeficienciesPerState();
    this.buildDeficienciesPerAreaPieData();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.deficienciesSubsciption.unsubscribe();
    this.technicalAreasSubsciption.unsubscribe();
  }

  subscribeToTechnicalAreas() {
    this.technicalAreasSubsciption = this.reportingAreasService.technicalAreasSubjet.subscribe(data => {
      this.technicalAreas = data;
      this.buildTechnicalAreaCodes();
    });

    this.reportingAreasService.emitTechnicalAreasSubject();
  }

  subscribeToDeficiencies() {
    this.deficienciesSubsciption = this.deficiencyService.confirmedDeficienciesSubjet.subscribe(data => {
      this.deficiencies = data;
      this.buildDeficienciesPerArea();
      this.buildDeficienciesPerState();
      this.buildDeficienciesPerAreaPieData();
    });

    this.deficiencyService.emitConfirmedDeficienciesSubject();
  }

  buildTechnicalAreaCodes() {
    if (this.technicalAreas) {
      this.areaCodes = [];
      this.technicalAreas.forEach(area => {
        this.areaCodes.push(area.areaCode);
      });
    }
  }

  buildDeficienciesPerArea() {

    if (this.deficiencies) {

      this.deficienciesPerArea = this.areaCodes.map(code => {
        const codeDeficiencies = this.deficiencies.filter(def => def.minReportingArea.technicalArea.areaCode === code);
        const openedCodeDeficiencies = codeDeficiencies.filter(def => !def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED));
        const resolvedCodeDeficiencies = codeDeficiencies.filter(def => def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED));

        const totalReportedDeficienciesCount = codeDeficiencies ? codeDeficiencies.length : 0;
        const openedDeficienciesCount = openedCodeDeficiencies ? openedCodeDeficiencies.length : 0;
        const resolvedDeficienciesCount = resolvedCodeDeficiencies ? resolvedCodeDeficiencies.length : 0;

        return { areaCode: code, totalReportedDeficiencies: totalReportedDeficienciesCount, openedDeficiencies: openedDeficienciesCount, resolvedDeficiencies: resolvedDeficienciesCount }
      });

    }
  }


  buildDeficienciesPerState() {
    // chart colors
    const totalReportedColor = 'blue';
    const openedDeficienciesColor = 'orange';
    const resolvedDeficienciesColor = 'seagreen';

    if (this.deficiencies) {

      const states: State[] = this.deficiencies.map(def => def.state);
      const stateNames = [];

      states.forEach(st => {
        if (!stateNames.includes(st.name)) {
          stateNames.push(st.name);
        }
      });

      const stateTotalReportedDeficiencies = stateNames.map(stateName => this.deficiencies.filter(def => def.state.name === stateName).length);

      const stateOpenedDeficiencies = stateNames.map(stateName => this.deficiencies
        .filter(def => def.state.name === stateName
          && !def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED)).length);

      const stateResolvedDeficiencies = stateNames.map(stateName => this.deficiencies
        .filter(def => def.state.name === stateName
          && def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED)).length);


      this.deficienciesPerStates = {
        labels: stateNames,
        datasets: [
          {
            label: this.labels.TotalRepored,
            backgroundColor: totalReportedColor,
            borderColor: totalReportedColor,
            data: stateTotalReportedDeficiencies
          },
          {
            label: this.labels.Opened,
            backgroundColor: openedDeficienciesColor,
            borderColor: openedDeficienciesColor,
            data: stateOpenedDeficiencies
          },
          {
            label: this.labels.Resolved,
            backgroundColor: resolvedDeficienciesColor,
            borderColor: resolvedDeficienciesColor,
            data: stateResolvedDeficiencies
          }
        ]
      };

    }
  }

  buildDeficienciesPerAreaPieData() {
    // const { limeColor, blueColor, tealColor, orangeColor, brownColor, purpleColor } = this.getColors();
    const borderColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

    const openedDeficienciesColor = 'orange';
    const resolvedDeficienciesColor = 'seagreen';

    if (this.deficiencies) {
      // const areaTotalDeficiencies = this.areaCodes.map(code => this.deficiencies.filter(def => def.minReportingArea.technicalArea.areaCode === code).length);

      const openedDeficiencies = this.deficiencies
        .filter(def => !def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED)).length;

      const resolvedDeficiencies = this.deficiencies
        .filter(def => def.statusHistory.find(stat => stat.status === DeficiencyService.STATUS_RO_CLOSED || stat.status === DeficiencyService.STATUS_PIRG_CLOSED)).length;

      const openedLabel = this.labels.Opened + ': ' + openedDeficiencies;
      const resolvedLabel = this.labels.Resolved + ': ' + resolvedDeficiencies;
      this.deficienciesPerAreaPieData = {
        labels: [openedLabel, resolvedLabel],
        datasets: [
          {
            data: [openedDeficiencies, resolvedDeficiencies],
            backgroundColor: [openedDeficienciesColor, resolvedDeficienciesColor],
            borderColor
          }]
      };

    }
  }


  getColors() {
    const isLight = this.app.layoutMode === 'light';
    return {
      pinkColor: isLight ? '#EC407A' : '#F48FB1',
      purpleColor: isLight ? '#AB47BC' : '#CE93D8',
      deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
      indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
      blueColor: isLight ? '#42A5F5' : '#90CAF9',
      lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
      cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
      tealColor: isLight ? '#26A69A' : '#80CBC4',
      greenColor: isLight ? '#66BB6A' : '#A5D6A7',
      lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
      limeColor: isLight ? '#D4E157' : '#E6EE9C',
      yellowColor: isLight ? 'FFEE58' : '#FFF59D',
      amberColor: isLight ? '#FFCA28' : '#FFE082',
      orangeColor: isLight ? '#FFA726' : '#FFCC80',
      deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
      brownColor: isLight ? '#8D6E63' : '#BCAAA4'
    }
  }

  getChartOptions() {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
      legend: {
        display: true,
        labels: {
          fontFamily,
          fontColor: textColor,
        }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            fontFamily,
            fontColor: textColor,
            min: 0
          },
          gridLines: {
            color: gridLinesColor
          }
        }],
        xAxes: [{
          ticks: {
            fontFamily,
            fontColor: textColor
          },
          gridLines: {
            color: gridLinesColor
          }
        }]
      }
    }
  }

  getChartOptions2() {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
      legend: {
        display: true,
        labels: {
          fontFamily,
          fontColor: textColor,
        }
      },
      responsive: true
    }
  }

  getChartOptions3() {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';

    return {
      legend: {
        display: true,
        labels: {
          fontFamily,
          fontColor: textColor,
        }
      },
      scale: {
        gridLines: {
          color: gridLinesColor
        },
        pointLabels: {
          fontColor: textColor
        }
      }
    }
  }

}
