import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { AppComponent } from 'src/app/app.component';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Router } from '@angular/router';
import { Authentication } from '../model/authentication';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-login-auth',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  SIGNUP = '/signup';
  PASSWORDFORGOT = 'pwdforgot';

  mainForm: FormGroup;

  labels = {
    LoginFailedMessage: 'Failed to login with given credentials !'
  };

  constructor(
    public appMain: AppMainComponent,
    public app: AppComponent,
    public router: Router,
    public authenticationService: AuthenticationService,
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.initMainForm();
  }

  initMainForm() {
    this.mainForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      remember: [false]
    });
  }

  onLogin() {
    if (this.mainForm.valid) {
      this.executeLogin();
    }
  }

  executeLogin() {
    const credentials = {
      username: this.mainForm.value.email as string,
      password: this.mainForm.value.password as string
    };

    const remember = this.mainForm.value.remember as boolean;

    this.authenticationService.login(credentials, remember)
      .then((authentication: Authentication) => {
        if (authentication.hasDefaultAdminRole) {
          this.router.navigate(['/system-administration/state-view']);
        } else {
          this.router.navigate(['/']);
        }
      }, () => {
        this.messageShowService.showLoginError(this.labels.LoginFailedMessage);
      });
  }

  onCloseMessage() {
    this.messageService.clear('loginMsgKey');
  }

  onRegister() {
    this.router.navigate([this.SIGNUP]);
  }

}
