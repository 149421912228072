import { Component, OnInit, OnDestroy } from '@angular/core';
import { SystemAdministrationService } from '../system-administration.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { Organization } from '../model/organization';
import { Subscription } from 'rxjs';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: ['./organization-view.component.scss']
})
export class OrganizationViewComponent extends Translatable implements OnInit, OnDestroy {


  ORGANIZATION_CREATE = '/system-administration/organization-create';
  ORGANIZATION_UPDATE = '/system-administration/organization-update';
  ORGANIZATION_VIEW = '/system-administration/organization-view';

  organizations: Organization[];
  selectedOrganization: Organization;

  organizationsSubsciption: Subscription;

  organizationTypes: SelectItem[];

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    Organizations: 'Organizations',
    New: 'New',
    Update: 'Update',
    Delete: 'Delete',
    Search: 'Search',
    Name: 'Name',
    Type: 'Type',
    StateName: 'State Name',
    Address: 'Address',
    Email: 'Email',
    Website: 'Web site',
    Phone: 'Phone',
    Fax: 'Fax',
    Nodata: 'No data found !',
    DeleteConfirmMessage: 'Are you sure that you want to delete ?',
    DeleteFailedMessage: 'Failed to delete the selected organization !',
    OrganizationTypeICAORO: 'ICAO Regional Office',
    OrganizationTypeStateCAA: 'State Civil Aviation Authority',
    OrganizationTypeStateANSP: 'State Air navigation service provider',
    OrganizationTypeStateAOP: 'State Aerodrome operator',
    OrganizationTypeInternationalOrg: 'International organization',
    OrganizationTypeUsersOrg: 'Users organization',
    OrganizationTypeProfessionalOrg: 'Professional organization'
  };


  constructor(
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public messageShowService: MessageShowService,
    public urlService: UrlService
  ) {
    super(
      'OrganizationViewComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.systemAdministrationService.loadOrganizations();
    this.subscribeToOrganizations();
    this.subscribeToPreferedLanguage();
    this.initMenuModel();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.organizationsSubsciption.unsubscribe();
  }

  initMenuModel() {
    this.organizationTypes = [
      {
        label: 'Select',
        value: null
      },
      {
        label: this.labels.OrganizationTypeICAORO,
        value: SystemAdministrationService.ORGANIZATION_TYPE_ICAORO
      },
      {
        label: this.labels.OrganizationTypeStateCAA,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA
      },
      {
        label: this.labels.OrganizationTypeStateANSP,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP
      },
      {
        label: this.labels.OrganizationTypeStateAOP,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
      },
      {
        label: this.labels.OrganizationTypeInternationalOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_INTERNATIONAL_ORG
      },
      {
        label: this.labels.OrganizationTypeUsersOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_USERS_ORG
      },
      {
        label: this.labels.OrganizationTypeProfessionalOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_PREFESSIONAL_ORG
      }
    ];
  }

  itemLabel(value: string){
    const item = this.organizationTypes.find(itm => itm.value === value);
    return item ? item.label : value;
  }

  subscribeToOrganizations() {
    this.organizationsSubsciption = this.systemAdministrationService.organizationsSubjet.subscribe(data => {
      this.organizations = data;
    });

    this.systemAdministrationService.emitOrganizationsSubject();
  }

  deleteSelectedItem() {
    if (this.selectedOrganization !== null) {
      this.confirmationService.confirm({
        key: 'deleteConfirm',
        message: this.labels.DeleteConfirmMessage,
        accept: () => {
          this.systemAdministrationService.deleteOrganization(this.selectedOrganization).then(() => {
            this.systemAdministrationService.loadOrganizations();
          }, () => {
            this.messageShowService.showWarning(this.labels.DeleteFailedMessage);
          });
          this.selectedOrganization = null;
        },
        reject: () => {
        }
      });
    }
  }

  updateSelectedItem() {
    if (this.selectedOrganization !== null) {
      this.systemAdministrationService.selectOrganization(this.selectedOrganization);
      this.router.navigate([this.ORGANIZATION_UPDATE]);
    }

  }

  onAdd() {
    this.router.navigate([this.ORGANIZATION_CREATE]);
  }


}
