import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthenticationService } from './security/authentication.service';
import { Authentication } from './security/model/authentication';
import { Subscription } from 'rxjs';
import { DeficiencyService } from './deficiencies/deficiency.service';
import { SystemAdministrationService } from './system-administration/system-administration.service';
import { Translatable } from './shared/utilities/translatable';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from './shared/services/locale.service';
import { UrlService } from './shared/services/url.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
        </ul>
    `
})
export class AppMenuComponent extends Translatable implements OnInit, OnDestroy {

    model: any[];

    authentication: Authentication;
    authenticationSubscription: Subscription;

    pendingNotificationsCount: number;
    pendingNotificationsCountSubscription: Subscription;

    activeDeficienciesCount: number;
    activeDeficienciesCountSubscription: Subscription;

    registeredUsersCount: number;
    registeredUsersCountSubscription: Subscription;

    rejectedNotificationsCount: number;
    rejectedNotificationsCountSubscription: Subscription;

    closedDeficienciesCount: number;
    closedDeficienciesCountSubscription: Subscription;


    // labels of this component to be translated,
    // translation resources to be setted in the files /assets/i18n/{locale}.json
    labels = {
        SystemAdministration: 'System administration',
        States: 'States',
        Organizations: 'Organizations',
        Roles: 'Roles',
        Users: 'Users',
        RegisteredUsers: 'Registered users',
        DeficiencyTable: 'Deficiency table',
        ReportingDeficiencies: 'Reporting deficiencies',
        TechnicalAreas: 'Technical areas',
        MinReportingAreas: 'Min reporting areas',
        ReportDeficiency: 'Report deficiency',
        MonitoringDeficiencies: 'Monitoring deficiencies',
        Dashboard: 'Dashboard',
        RejectedNotifications: 'Rejected notifications',
        PendingNotifications: 'Pending notifications',
        ActiveDeficiencies: 'Active deficiencies',
        ClosedDeficiencies: 'Closed deficiencies',
        FiledDeficiencies: 'Filed deficiencies',
        GenerateReport: 'Generate report'
    };

    constructor(
        public systemAdministrationService: SystemAdministrationService,
        public app: AppComponent,
        public translateService: TranslateService,
        public authenticationService: AuthenticationService,
        public localeService: LocaleService,
        public deficiencyService: DeficiencyService,
        public router: Router,
        public urlService: UrlService
    ) {
        super(
            'AppMenuComponent',
            translateService,
            localeService,
            router,
            urlService
        );
    }

    subscribeToRegisteredUsersCount() {
        this.registeredUsersCountSubscription = this.systemAdministrationService.registeredUsersCountSubjet.subscribe(data => {
            this.registeredUsersCount = data;
            this.initMenuModel();
        });

        this.systemAdministrationService.emitRegisteredUsersCountSubject();
    }

    subscribeToPendingNotificationsCount() {
        this.pendingNotificationsCountSubscription = this.deficiencyService.pendingNotificationsSubjet.subscribe(data => {
            this.pendingNotificationsCount = data ? data.length : 0;
            this.initMenuModel();
        });
        this.deficiencyService.emitPendingNotificationsSubject();
    }

    subscribeTorejectedNotificationsCount() {
        this.rejectedNotificationsCountSubscription = this.deficiencyService.rejectedNotificationsSubjet.subscribe(data => {
            this.rejectedNotificationsCount = data ? data.length : 0;
            this.initMenuModel();
        });
        this.deficiencyService.emitRejectedNotificationsSubject();
    }


    subscribeToClosedDeficienciesCount() {
        this.closedDeficienciesCountSubscription = this.deficiencyService.closedDeficienciesSubjet.subscribe(data => {
            this.closedDeficienciesCount = data ? data.length : 0;
            this.initMenuModel();
        });
        this.deficiencyService.emitClosedDeficienciesSubject();
    }

    subscribeToActiveDeficienciesCount() {
        this.activeDeficienciesCountSubscription = this.deficiencyService.deficienciesSubjet.subscribe(data => {
            this.activeDeficienciesCount = data ? data.length : 0;
            this.initMenuModel();
        });
        this.deficiencyService.emitDeficienciesSubject();
    }

    subscribeAuthentication() {
        this.authenticationSubscription = this.authenticationService.authenticationSubjct.subscribe((authentication: Authentication) => {
            this.authentication = authentication;
            this.initMenuModel();
        });
        this.authenticationService.emitAuthenticationSubjctSubjct();
    }

    ngOnInit() {
        this.deficiencyService.loadDeficiencies();
        this.systemAdministrationService.loadUsers();
        this.subscribeToPreferedLanguage();
        this.subscribeAuthentication();
        this.subscribeToPendingNotificationsCount();
        this.subscribeTorejectedNotificationsCount();
        this.subscribeToActiveDeficienciesCount();
        this.subscribeToRegisteredUsersCount();
        this.subscribeToClosedDeficienciesCount();
        this.initUrl();
    }

    ngOnDestroy(): void {
        this.preferedLanguageSubscription.unsubscribe();
        this.pendingNotificationsCountSubscription.unsubscribe();
        this.rejectedNotificationsCountSubscription.unsubscribe();
        this.activeDeficienciesCountSubscription.unsubscribe();
        this.registeredUsersCountSubscription.unsubscribe();
        this.closedDeficienciesCountSubscription.unsubscribe();
        this.authenticationSubscription.unsubscribe();
    }

    initMenuModel() {
        this.model = [
            {
                label: this.labels.SystemAdministration, icon: 'pi pi-fw pi-cog', routerLink: ['/system-administration'],
                items: [
                    { label: this.labels.States, icon: 'pi pi-fw pi-globe', routerLink: ['/system-administration/state-view'] },
                    { label: this.labels.Organizations, icon: 'pi pi-fw pi-home', routerLink: ['/system-administration/organization-view'] },
                    { label: this.labels.Roles, icon: 'pi pi-fw pi-tags', routerLink: ['/system-administration/role-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasDefaultAdminRole)
                    },
                    { label: this.labels.Users, icon: 'pi pi-fw pi-users', routerLink: ['/system-administration/user-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasDefaultAdminRole)
                    },
                    {
                        label: this.labels.RegisteredUsers, icon: 'pi pi-fw pi-users', routerLink: ['/system-administration/user-registered-view'],
                        badge: this.registeredUsersCount !== null && this.registeredUsersCount !== undefined ? this.registeredUsersCount : '0', badgeClass: 'p-badge-warning',
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasDefaultAdminRole)
                    },
                    { label: this.labels.DeficiencyTable, icon: 'pi pi-fw pi-refresh', routerLink: ['/system-administration/deficiency-table'],
                        visible: this.authentication && this.authentication.hasDefaultAdminRole
                    }
                ],
                visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasDefaultAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole)
            },
            {
                label: this.labels.ReportingDeficiencies, icon: 'pi pi-fw pi-star', routerLink: ['/reporting-areas'],
                visible: this.authentication && !this.authentication.hasDefaultAdminRole,
                items: [
                    {
                        label: this.labels.TechnicalAreas, icon: 'pi pi-fw pi-folder', routerLink: ['/reporting-areas/technical-area-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole)
                    },
                    { label: this.labels.MinReportingAreas, icon: 'pi pi-fw pi-check-square', routerLink: ['/reporting-areas/min-reporting-area-view'] },
                    { label: this.labels.ReportDeficiency, icon: 'pi pi-fw pi-calendar-minus', routerLink: ['/deficiencies/deficiency-create'] }
                ]
            },
            {
                label: this.labels.MonitoringDeficiencies, icon: 'pi pi-fw pi-star', routerLink: ['/deficiencies'],
                visible: this.authentication && !this.authentication.hasDefaultAdminRole,
                items: [
                    { label: this.labels.Dashboard, icon: 'pi pi-fw pi-chart-bar', routerLink: ['/deficiencies/dashboard'] },
                    {
                        label: this.labels.GenerateReport, icon: 'pi pi-fw pi-align-left', routerLink: ['/report/deficiency-report-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole || this.authentication.hasStateUserRole)
                    },
                    {
                        label: this.labels.RejectedNotifications, icon: 'pi pi-fw pi-ban', routerLink: ['/deficiencies/rejected-notification-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole),
                        badge: this.rejectedNotificationsCount !== null && this.rejectedNotificationsCount !== undefined ? this.rejectedNotificationsCount : '0', badgeClass: 'p-badge-info'
                    },
                    {
                        label: this.labels.PendingNotifications, icon: 'pi pi-fw pi-bell', routerLink: ['deficiencies/pending-notification-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole),
                        badge: this.pendingNotificationsCount !== null && this.pendingNotificationsCount !== undefined ? this.pendingNotificationsCount : '0', badgeClass: 'p-badge-warning'
                    },
                    {
                        label: this.labels.ActiveDeficiencies, icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/deficiencies/deficiency-view'],
                        badge: this.activeDeficienciesCount !== null && this.activeDeficienciesCount !== undefined ? this.activeDeficienciesCount : '0', badgeClass: 'p-badge-danger'
                    },
                    {
                        label: this.labels.ClosedDeficiencies, icon: 'pi pi-fw pi-power-off', routerLink: ['/deficiencies/closed-deficiency-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole),
                        badge: this.closedDeficienciesCount !== null && this.closedDeficienciesCount !== undefined ? this.closedDeficienciesCount : '0', badgeClass: 'p-badge-success'
                    },
                    {
                        label: this.labels.FiledDeficiencies, icon: 'pi pi-fw pi-inbox', routerLink: ['/deficiencies/filed-deficiency-view'],
                        visible: this.authentication && (this.authentication.hasAdminRole || this.authentication.hasIcaoDRDRole || this.authentication.hasIcaoRDRole || this.authentication.hasIcaoRORole)
                    }
                ]
            }
        ];
    }
}
