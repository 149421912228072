import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { RepositoryService } from 'src/app/shared/services/repository.service';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { ReportDocument } from './model/report-document';
import { LocaleService } from '../shared/services/locale.service';
import { Deficiency } from '../deficiencies/model/deficiency';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  static REPORT_TYPE_REPORTED_DEFICIENCIES = 'REPORTED_DEFICIENCIES';
  static REPORT_TYPE_CLOSED_DEFICIENCIES = 'CLOSED_DEFICIENCIES';

  reportDocument: ReportDocument;
  reportDocumentSubject = new Subject<ReportDocument>();

  constructor(
    public repositoryService: RepositoryService,
    public authenticationService: AuthenticationService,
    public localeService: LocaleService
  ) { }

  // Generate reports

  generateSummaryReport(reportType: string): Promise<any> {
    const locale = this.localeService.getPreferedLanguage().locale;
    const route = HttpMapping.DEFICIENCY_GENERATE_SUMMARY_REPORT.replace('{locale}', locale).replace('{reportType}', reportType);

    // remote backend service call {reportType}
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.reportDocument = data as ReportDocument;
        this.emitReportDocumentSubject();
        resolve();
      }, () => {
        reject();
      });
    });
  }

  // DEFICIENCY_GENERATE_REPORT_BY_ID
  generateDeficiencyReportForICAO(deficiency: Deficiency): Promise<any> {
    const locale = this.localeService.getPreferedLanguage().locale;
    const route = HttpMapping.DEFICIENCY_GENERATE_REPORT_ICAO_BY_ID.replace('{id}', deficiency.id).replace('{locale}', locale);

    // remote backend service call {reportType}
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.reportDocument = data as ReportDocument;
        this.emitReportDocumentSubject();
        resolve();
      }, () => {
        reject();
      });
    });
  }


  // DEFICIENCY_GENERATE_REPORT_BY_ID
  generateDeficiencyReportForState(deficiency: Deficiency): Promise<any> {
    const locale = this.localeService.getPreferedLanguage().locale;
    const route = HttpMapping.DEFICIENCY_GENERATE_REPORT_STATE_BY_ID.replace('{id}', deficiency.id).replace('{locale}', locale);

    // remote backend service call {reportType}
    return new Promise((resolve, reject) => {
      this.repositoryService.get(route).subscribe(data => {
        this.reportDocument = data as ReportDocument;
        this.emitReportDocumentSubject();
        resolve();
      }, () => {
        reject();
      });
    });
  }


  emitReportDocumentSubject() {
    this.reportDocumentSubject.next(this.reportDocument);
  }
}
