import { Injectable } from '@angular/core';
import { State } from './model/state';
import { Subject } from 'rxjs';
import { Organization } from './model/organization';
import { v4 as uuid } from 'uuid';
import { Role } from './model/role';
import { User } from './model/user';
import { HttpMapping } from '../shared/constants/http-mapping';
import { RepositoryService } from '../shared/services/repository.service';

@Injectable({
  providedIn: 'root'
})
export class SystemAdministrationService {


  // Organization types
  static ORGANIZATION_TYPE_ICAORO = 'ICAO_REGIONAL_OFFICE';
  static ORGANIZATION_TYPE_STATE_CAA = 'STATE_CIVIL_AVIATION_AUTHORITY';
  static ORGANIZATION_TYPE_STATE_ANSP = 'STATE_AIR_NAVIGATION_SERVICE_PROVIDER';
  static ORGANIZATION_TYPE_STATE_AOP = 'STATE_AERODROME_OPERATOR';
  static ORGANIZATION_TYPE_INTERNATIONAL_ORG = 'INTERNATIONAL_ORGANIZATION';
  static ORGANIZATION_TYPE_USERS_ORG = 'USERS_ORGANIZATION';
  static ORGANIZATION_TYPE_PREFESSIONAL_ORG = 'PROFESSIONAL_ORGANIZATION';

  coreRolesList: string[] = [
    'SYSTEM_ADMINISTRATOR', 'REGIONAL_OFFICER', 'DEPUTY_REGIONAL_DIRECTOR', 'REGIONAL_DIRECTOR', 'STATE_USER', 'OTHER_USER', 'STATE_DGCA', 'PIRG_SECRETARY'
  ];

  userStatusList = [
    { status: 'ACTIVATED', label: 'Activated' }, { status: 'NOT_ACTIVATED', label: 'Not activated' }, { status: 'DEACTIVATED', label: 'Deactivated' }
  ];


  /* States */
  states: State[];
  statesSubjet = new Subject<State[]>();

  selectedState: State;
  selectedStateSubjet = new Subject<State>();



  /* Organizations */
  regionalOffices: Organization[];
  regionalOfficesSubjet = new Subject<Organization[]>();

  /* Organizations */
  organizations: Organization[];
  organizationsSubjet = new Subject<Organization[]>();

  selectedOrganization: Organization;
  selectedOrganizationSubjet = new Subject<Organization>();


  /* Roles */
  roles: Role[];
  rolesSubjet = new Subject<Role[]>();

  selectedRole: Role;
  selectedRoleSubjet = new Subject<Role>();


  /* Roles */
  users: User[];
  usersSubjet = new Subject<User[]>();
  activeUserTab: number;
  activeUserTabSubjet = new Subject<number>();

  activeRegisteredUserTab: number;
  activeRegisteredUserTabSubjet = new Subject<number>();


  registeredUsers: User[];
  registeredUsersSubjet = new Subject<User[]>();

  registeredUsersCount: number;
  registeredUsersCountSubjet = new Subject<number>();


  selectedUser: User;
  selectedUserSubjet = new Subject<User>();

  icaoUsers: User[];
  icaoUsersSubjet = new Subject<User[]>();

  constructor(
    public repositoryService: RepositoryService
  ) {
  }

  get coreRoles() {
    return this.coreRolesList;
  }

  get coreUserStatus() {
    return this.userStatusList;
  }

  get coreUsers() {
    return this.users;
  }

  get coreStates() {
    return this.states;
  }

  /* == Following methods handle states ===== */

  loadStates() {
    const route = HttpMapping.STATE_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGet(route).subscribe(data => {
        this.states = data as State[];
        this.states = this.states ? this.states.sort((a, b) => a.name.localeCompare(b.name)) : [];
        this.emitStatesSubject();

        resolve();
      }, () => {
        reject();
      });
    });
  }

  emitStatesSubject() {
    this.statesSubjet.next(this.states);
  }

  emitSelectedStateSubject() {
    this.selectedStateSubjet.next(this.selectedState);
  }

  selectState(state: State) {
    this.selectedState = state;
    this.emitSelectedStateSubject();
  }

  deleteState(state: State): Promise<any> {

    const route = HttpMapping.STATE_DELETE_BY_ID.replace('{id}', state.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityDelete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createState(state: State): Promise<any> {
    const route = HttpMapping.STATE_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityCreate(route, state).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateState(state: State): Promise<any> {

    const route = HttpMapping.STATE_PUT_BY_ID.replace('{id}', state.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityUpdate(route, state).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  /* == Following methods handle organizations ===== */
  loadOrganizations() {
    const route = HttpMapping.ORGANIZATION_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGet(route).subscribe(data => {
        this.organizations = data as Organization[];
        this.organizations = this.organizations ? this.organizations.sort((a, b) => a.name.localeCompare(b.name)) : [];
        this.regionalOffices = this.organizations.filter(org => org.type === SystemAdministrationService.ORGANIZATION_TYPE_ICAORO);

        this.emitOrganizationsSubject();
        this.emitRegionalOfficesSubject();

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  loadOrganizationsNoAuthorization() {
    const route = HttpMapping.ORGANIZATION_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGetNoAuthorization(route).subscribe(data => {
        this.organizations = data as Organization[];
        this.organizations = this.organizations ? this.organizations.sort((a, b) => a.name.localeCompare(b.name)) : [];
        this.regionalOffices = this.organizations.filter(org => org.type === SystemAdministrationService.ORGANIZATION_TYPE_ICAORO);
        this.emitOrganizationsSubject();
        this.emitRegionalOfficesSubject();

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }


  emitOrganizationsSubject() {
    this.organizationsSubjet.next(this.organizations);
  }

  emitRegionalOfficesSubject() {
    this.regionalOfficesSubjet.next(this.regionalOffices);
  }

  emitSelectedOrganizationSubject() {
    this.selectedOrganizationSubjet.next(this.selectedOrganization);
  }

  selectOrganization(organization: Organization) {
    this.selectedOrganization = organization;
    this.emitSelectedOrganizationSubject();
  }

  deleteOrganization(organization: Organization): Promise<any> {
    const route = HttpMapping.ORGANIZATION_DELETE_BY_ID.replace('{id}', organization.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityDelete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createOrganization(organization: Organization): Promise<any> {
    const route = HttpMapping.ORGANIZATION_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityCreate(route, organization).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateOrganization(organization: Organization): Promise<any> {
    const route = HttpMapping.ORGANIZATION_PUT_BY_ID.replace('{id}', organization.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityUpdate(route, organization).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  /* == Following methods handle roles ===== */

  loadRoles() {
    const route = HttpMapping.ROLE_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGet(route).subscribe(data => {
        this.roles = data as Role[];
        this.emitRolesSubject();

        resolve();
      }, () => {
        reject();
      });
    });
  }

  emitRolesSubject() {
    this.rolesSubjet.next(this.roles);
  }

  emitSelectedRoleSubject() {
    this.selectedRoleSubjet.next(this.selectedRole);
  }

  selectRole(role: Role) {
    this.selectedRole = role;
    this.emitSelectedRoleSubject();
  }

  deleteRole(role: Role): Promise<any> {
    const route = HttpMapping.ROLE_DELETE_BY_ID.replace('{id}', role.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityDelete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createRole(role: Role): Promise<any> {
    const route = HttpMapping.ROLE_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityCreate(route, role).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateRole(role: Role): Promise<any> {
    const route = HttpMapping.ROLE_PUT_BY_ID.replace('{id}', role.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityUpdate(route, role).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  /* == Following methods handle users ===== */

  emitActiveUserTabSubject() {
    this.activeUserTabSubjet.next(this.activeUserTab);
  }

  setActiveUserTab(tab: number) {
    this.activeUserTab = tab;
    this.emitActiveUserTabSubject();
  }

  emitActiveRegisteredUserTabSubject() {
    this.activeRegisteredUserTabSubjet.next(this.activeRegisteredUserTab);
  }

  setActiveRegisteredUserTab(tab: number) {
    this.activeRegisteredUserTab = tab;
    this.emitActiveRegisteredUserTabSubject();
  }

  loadUsers() {
    const route = HttpMapping.USER_GET_ALL;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGet(route).subscribe(data => {
        this.users = data as User[];
        this.users = this.users ? this.users.sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)) : [];
        this.icaoUsers = this.users.filter(usr => usr.organization.type === SystemAdministrationService.ORGANIZATION_TYPE_ICAORO);

        // registered users
        this.registeredUsers = this.users.filter(usr => usr.status === 'NOT_ACTIVATED');
        this.registeredUsersCount = this.registeredUsers !== null && this.registeredUsers !== undefined ? this.registeredUsers.length : 0;

        this.emitUsersSubject();
        this.emitIcaoUsersSubject();
        this.emitRegisteredUsersSubject();
        this.emitRegisteredUsersCountSubject();

        resolve();
      }, () => {
        reject();
      });
    });
  }

  emitUsersSubject() {
    this.usersSubjet.next(this.users);
  }

  emitIcaoUsersSubject() {
    this.icaoUsersSubjet.next(this.icaoUsers);
  }

  emitRegisteredUsersSubject() {
    this.registeredUsersSubjet.next(this.registeredUsers);
  }

  emitRegisteredUsersCountSubject() {
    this.registeredUsersCountSubjet.next(this.registeredUsersCount);
  }

  emitSelectedUserSubject() {
    this.selectedUserSubjet.next(this.selectedUser);
  }

  selectUser(user: User) {
    this.selectedUser = user;
    this.emitSelectedUserSubject();
  }

  deleteUser(user: User): Promise<any> {
    const route = HttpMapping.USER_DELETE_BY_ID.replace('{id}', user.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityDelete(route).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  createUser(user: User): Promise<any> {
    const route = HttpMapping.USER_POST;

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityCreate(route, user).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  updateUser(user: User): Promise<any> {
    const route = HttpMapping.USER_PUT_BY_ID.replace('{id}', user.id);

    // remote backend service call
    return new Promise((resolve, reject) => {
      this.repositoryService.securityUpdate(route, user).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  changePassword(userName: string, currentPassword: string, newPassword: string): Promise<any> {
    const route = HttpMapping.USER_PUT_PASS_BY_USERNAME
      .replace('{username}', userName)
      .replace('{currentpassword}', currentPassword)
      .replace('{newpassword}', newPassword);

    // remote backend service call {username}/{currentpassword}/{newpassword}
    return new Promise((resolve, reject) => {
      this.repositoryService.securityUpdate(route, null).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }


  verifyEmailAdress(email: string): Promise<any> {

    const route = HttpMapping.USER_GET_CHECK_EMAIL
      .replace('{email}', email)

    // remote backend service call {username}/{currentpassword}/{newpassword}
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGetNoAuthorization(route).subscribe(data => {
        const emailCheck = data;
        resolve(emailCheck);
      }, (err) => {
        reject(err);
      });
    });

  }

  userSignup(user: User): Promise<any> {

    const route = HttpMapping.USER_POST_SIGNUP.replace('{passwd}', user.password);

    // remote backend service call {username}/{currentpassword}/{newpassword}
    return new Promise((resolve, reject) => {
      this.repositoryService.securityCreateNoAuthorization(route, user).subscribe(data => {
        const userRegistered = data;
        resolve(userRegistered);
      }, (err) => {
        reject(err);
      });
    });

  }

  passwordResetCode(email: string): Promise<any> {

    const route = HttpMapping.USER_GET_PASSWD_RESET_CODE
      .replace('{email}', email)

    // remote backend service call {username}/{currentpassword}/{newpassword}
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGetNoAuthorization(route).subscribe(data => {
        const emailCheck = data;
        resolve(emailCheck);
      }, (err) => {
        reject(err);
      });
    });

  }

  resetPasswordForgot(email: string, newpassword): Promise<any> {

    const route = HttpMapping.USER_GET_PASSWD_RESET_PROCEED
      .replace('{email}', email)
      .replace('{newpassword}', newpassword)

    // remote backend service call {email}/{newpassword}
    return new Promise((resolve, reject) => {
      this.repositoryService.securityGetNoAuthorization(route).subscribe(data => {
        const emailCheck = data;
        resolve(emailCheck);
      }, (err) => {
        reject(err);
      });
    });

  }

}
