import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ReportDocumentTable } from '../../model/report-document-table';

@Component({
  selector: 'app-document-viewer-table',
  templateUrl: './document-viewer-table.component.html',
  styleUrls: ['./document-viewer-table.component.css']
})
export class DocumentViewerTableComponent implements OnInit, AfterViewInit {

  @Input() reportTable: ReportDocumentTable;
  @Input() heightLimit: number;
  @Output() remaingTableOut = new EventEmitter<ReportDocumentTable>();


  tableHeight: number;
  rowsHeight: number;


  remainingTable: ReportDocumentTable;
  remainingFirstIndexe: number;

  @ViewChild('tableDiv')
  tableDiv: ElementRef;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.checkElementHeight();

  }

  checkElementHeight() {
    const div = this.tableDiv.nativeElement as HTMLDivElement;
    this.tableHeight = div.offsetHeight;
  }

  /* Handle reported row height and evaluate this tatle total height
  *  If this table height exceeds the heightLimit value, a new table [remainingTable] will be created to hold exceeding rows
  *  Upon handling the last row of this table, report eventually remainingTable
  */
  handleTableRowOut(event: { index: number, height: number }) {
    this.rowsHeight = this.rowsHeight ? this.rowsHeight + event.height : event.height;

    if (this.heightLimit != null && this.rowsHeight > this.heightLimit) {

      // save first reamining row index
      if (!this.remainingFirstIndexe) { this.remainingFirstIndexe = event.index; }

      // remove exceeding rows from this table and add to [remainingTable]
      if (event.index === (this.reportTable.rows.length - 1)) {

        // add excedding row to remainintTable
        if (!this.remainingTable) { this.remainingTable = new ReportDocumentTable(); }
        if (!this.remainingTable.rows) { this.remainingTable.rows = []; }
        let lastIndex = event.index;

        while (lastIndex >= this.remainingFirstIndexe) {
          const row = this.reportTable.rows.pop();
          this.remainingTable.rows.unshift(row);
          lastIndex--;
        }

        // add repeatable rows ahead
        const repeatableRows = this.reportTable.rows
          .filter(row => row.style && row.style['repeated-row-order'] !== null && row.style['repeated-row-order'] !== undefined)
          .sort((a, b) => a.style['repeated-row-order'] - b.style['repeated-row-order']);

        if (repeatableRows) {
          let count = repeatableRows.length - 1;
          while (count >= 0) {
            const clonedRow = Object.assign([], repeatableRows[count]);
            this.remainingTable.rows.unshift(clonedRow);
            count--;
          }
        }

        this.remaingTableOut.emit(this.remainingTable);

      }
    }
  }
}
