<div class="pages-body login-page p-d-flex p-flex-column">
    <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
        <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
            <div class="logo">
                <!-- <img src="assets/layout/images/logo-afi-andd-1.svg" alt="" /> -->
                <svg height="30" width="450">
                    <text x="20" y="20" fill="white" font-size="22px" font-weight="bold">AFI Air Navigation Deficiencies Database</text>
                </svg>
            </div>
        </div>
        <div class="topbar-right p-mr-3 p-d-flex">
        </div>
    </div>

    <p-toast position="center" key="loginMsgKey" life="10000" (onClose)="onCloseMessage()" [baseZIndex]="5000">
    </p-toast>

    <div class="p-as-center p-mt-auto p-mb-auto" style="min-width: 500px;">
        <div class="pages-panel card p-d-flex p-flex-column">
            <div class="pages-header p-px-3 p-py-1">
                <h2>LOGIN</h2>
            </div>

            <div  class="p-mt-6 p-mb-3 p-mr-3">
                <span style="font-size: 14px; font-style: italic;">Welcome to the Afi air navigation deficiencies database - AANDD</span>
            </div>
            

            <div class="pages-detail p-mb-6 p-px-6">
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onLogin()">
                <div class="input-panel p-d-flex p-flex-column p-px-3">

                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <input type="text" id="email" pInputText formControlName="email">
                            <label for="email">Email</label>
                        </span>
                    </div>

                    <div class="p-inputgroup p-mt-3">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <input type="password" id="password" pInputText formControlName="password">
                            <label for="password">Password</label>
                        </span>
                    </div>
                    <div class="p-inputgroup p-mt-3 p-mb-3">
                        <input type="checkbox" class="ui-chkbox ui-chkbox-box" formControlName="remember" id="binary">
                        <label for="binary" style="color: blue; cursor: pointer;">Remember me</label>
                    </div>

                    <div class="p-inputgroup p-mt-3 p-mb-3">
                        <span class="p-inputgroup-addon" style="border: 0ch; ">
                            <p-button class="login-button p-mr-2 p-mb-2" type="submit" label="Log in" [style]="{'width': '100px'}"
                                [disabled]="!mainForm.valid"> 
                            </p-button>
                        </span>
                    </div>
                </div>
                <div>
                    <hr>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <div class="p-mt-3 p-mb-3">
                            <span style="align-content: flex-start"> Don't have an account?  <a [routerLink]="SIGNUP" style="color: blue;">Sign Up</a></span>
                        </div>
                    </div>
                    <div class="p-field p-col-12 p-md-6" align="right">
                        <div class="p-mt-3 p-mb-3 p-mr-3">
                            <span style="text-align: right;"><a [routerLink]="PASSWORDFORGOT" style="color: blue;">Forgot password?</a></span>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>
