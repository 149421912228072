import { Component, OnInit, OnDestroy } from '@angular/core';
import { Organization } from '../model/organization';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Translatable } from 'src/app/shared/utilities/translatable';
import { State } from '../model/state';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MessageShowService } from 'src/app/shared/services/message-show.service';
import { SystemAdministrationService } from '../system-administration.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { UrlService } from 'src/app/shared/services/url.service';

@Component({
  selector: 'app-organization-update',
  templateUrl: './organization-update.component.html',
  styleUrls: ['./organization-update.component.scss']
})
export class OrganizationUpdateComponent extends Translatable implements OnInit, OnDestroy {


  ORGANIZATION_CREATE = '/system-administration/organization-create';
  ORGANIZATION_UPDATE = '/system-administration/organization-update';
  ORGANIZATION_VIEW = '/system-administration/organization-view';

  mainForm: FormGroup;

  showStates: boolean;

  states: State[];
  selectedOrganization: Organization;

  statesItems: SelectItem[];

  organizationTypes: SelectItem[];



  statesSubsciption: Subscription;
  selectedOrganizationSubsciption: Subscription;

  // labels of this component to be translated,
  // translation resources to be setted in the files /assets/i18n/{locale}.json
  labels = {
    UpdateOrganization: 'Update Organization',
    Name: 'Name',
    Type: 'Type',
    State: 'State',
    Address: 'Address',
    Email: 'Email',
    Website: 'Web site',
    Phone: 'Phone',
    Fax: 'Fax',
    Cancel: 'Cancel',
    Save: 'Save',
    SaveSuccesMessage: 'Saved with success !',
    SaveFailedMessage: 'Failed to save !',
    OrganizationTypeICAORO: 'ICAO Regional Office',
    OrganizationTypeStateCAA: 'State Civil Aviation Authority',
    OrganizationTypeStateANSP: 'State Air navigation service provider',
    OrganizationTypeStateAOP: 'State Aerodrome operator',
    OrganizationTypeInternationalOrg: 'International organization',
    OrganizationTypeUsersOrg: 'Users organization',
    OrganizationTypeProfessionalOrg: 'Professional organization'
  };


  constructor(
    public fb: FormBuilder,
    public messageShowService: MessageShowService,
    public systemAdministrationService: SystemAdministrationService,
    public confirmationService: ConfirmationService,
    public router: Router,
    public translateService: TranslateService,
    public localeService: LocaleService,
    public urlService: UrlService
  ) {
    super(
      'OrganizationUpdateComponent',
      translateService,
      localeService,
      router,
      urlService
    );
  }


  ngOnInit(): void {
    this.initMainForm();
    this.systemAdministrationService.loadStates();
    this.subscribeToPreferedLanguage();
    this.subscribeToStates();
    this.subscribeToSelectedOrganization();
    this.initMenuModel();
    this.initUrl();
  }

  ngOnDestroy(): void {
    this.preferedLanguageSubscription.unsubscribe();
    this.statesSubsciption.unsubscribe();
    this.selectedOrganizationSubsciption.unsubscribe();
  }

  initMenuModel() {
    this.organizationTypes = [
      {
        label: 'Select',
        value: null
      },
      {
        label: this.labels.OrganizationTypeICAORO,
        value: SystemAdministrationService.ORGANIZATION_TYPE_ICAORO
      },
      {
        label: this.labels.OrganizationTypeStateCAA,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA
      },
      {
        label: this.labels.OrganizationTypeStateANSP,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP
      },
      {
        label: this.labels.OrganizationTypeStateAOP,
        value: SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
      },
      {
        label: this.labels.OrganizationTypeInternationalOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_INTERNATIONAL_ORG
      },
      {
        label: this.labels.OrganizationTypeUsersOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_USERS_ORG
      },
      {
        label: this.labels.OrganizationTypeProfessionalOrg,
        value: SystemAdministrationService.ORGANIZATION_TYPE_PREFESSIONAL_ORG
      }
    ];
  }

  subscribeToStates() {
    this.statesSubsciption = this.systemAdministrationService.statesSubjet.subscribe(data => {
      this.states = data;

      if (this.states) {
        this.statesItems = [];
        this.statesItems.push({ label: 'Select', value: null });

        this.states.forEach(item => {
          this.statesItems.push({
            label: item.name,
            value: item.id
          });
        });
      }
    });

    this.systemAdministrationService.emitStatesSubject();
  }

  subscribeToSelectedOrganization() {
    this.selectedOrganizationSubsciption = this.systemAdministrationService.selectedOrganizationSubjet.subscribe(data => {
      this.selectedOrganization = data;

      this.fillForm();
    });

    this.systemAdministrationService.emitSelectedOrganizationSubject();
  }


  fillForm() {
    this.mainForm.patchValue({
      name: this.selectedOrganization.name,
      type: this.selectedOrganization.type,
      state: this.selectedOrganization.state ? this.selectedOrganization.state.id : null,
      address: this.selectedOrganization.address,
      email: this.selectedOrganization.email,
      website: this.selectedOrganization.website,
      phone: this.selectedOrganization.phone,
      fax: this.selectedOrganization.fax,
    });

    if (
      [SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA,
      SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP,
      SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
      ].includes(this.selectedOrganization.type)) {
      this.showStates = true;
    } else {
      this.showStates = false;
    }

  }

  initMainForm() {
    this.mainForm = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      state: ['', []],
      address: ['', [Validators.required]],
      email: ['', [Validators.required]],
      website: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      fax: ['', [Validators.required]]
    });

    this.showStates = true;
  }

  onTypeChange() {
    const type = this.mainForm.value.type;
    if (
      [SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA,
      SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP,
      SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
      ].includes(type)) {
      this.showStates = true;
    } else {
      this.showStates = false;
    }

  }

  onCancel() {
    this.router.navigate([this.ORGANIZATION_VIEW]);
  }

  onSave() {
    if (this.mainForm.valid) {
      this.executeSave();
    }
  }

  executeSave() {
    const type = this.mainForm.value.type;

    this.selectedOrganization.name = this.mainForm.value.name;
    this.selectedOrganization.type = this.mainForm.value.type;
    this.selectedOrganization.state = [SystemAdministrationService.ORGANIZATION_TYPE_STATE_CAA,
                                        SystemAdministrationService.ORGANIZATION_TYPE_STATE_ANSP,
                                        SystemAdministrationService.ORGANIZATION_TYPE_STATE_AOP
                                      ].includes(type) ?
                                      this.states.find(state => state.id === this.mainForm.value.state) : null;

    this.selectedOrganization.address = this.mainForm.value.address;
    this.selectedOrganization.email = this.mainForm.value.email;
    this.selectedOrganization.website = this.mainForm.value.website;
    this.selectedOrganization.phone = this.mainForm.value.phone;
    this.selectedOrganization.fax = this.mainForm.value.fax;

    this.systemAdministrationService.updateOrganization(this.selectedOrganization).then(() => {
      this.systemAdministrationService.loadOrganizations();
      this.messageShowService.showSuccess(this.labels.SaveSuccesMessage);
    }, () => {
      this.messageShowService.showWarning(this.labels.SaveFailedMessage);
    });

    this.router.navigate([this.ORGANIZATION_VIEW]);
  }

}
