import { Component, OnInit, Input, ElementRef, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpMapping } from 'src/app/shared/constants/http-mapping';
import { AppComponent } from 'src/app/app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportDocumentTableRow } from '../../model/report-document-table-row';

@Component({
  selector: 'app-document-viewer-table-row',
  templateUrl: './document-viewer-table-row.component.html',
  styleUrls: ['./document-viewer-table-row.component.css']
})
export class DocumentViewerTableRowComponent implements OnInit, AfterViewInit {

  @Input() tableRow: ReportDocumentTableRow;
  @Input() rowIndex: number;
  @Output() tableRowOut = new EventEmitter<{ index: number, height: number }>();

  rowHeight: number;

  imageSrcMap = new Map<string, any>();

  imageLoadRequested = new Map<string, string>();

  @ViewChild('rowDiv')
  rowDiv: ElementRef;

  constructor(
    public app: AppComponent
    // private domSanitizer: DomSanitizer,
    // public fileLoadingService: FileLoadingService
  ) { }

  ngOnInit() {
  }

  /*
  loadImage(image) {
    if (!this.imageLoadRequested.has(image)) {
      this.imageLoadRequested.set(image, image);
      this.downloadLogo(image, image);
    }
    return this.imageSrcMap.get(image);
  }

  downloadLogo(logo, key: string) {
    const route = HttpMapping.FILE_DOWNLOAD.replace('{filename}', logo);
    this.fileLoadingService.downloadFile(route, FileLoadingService.SERVICE_SECURITY).subscribe((data) => {
      this.displayLogo(data.body, key);
    }, err => {
      console.log(err);
    });
  }

  displayLogo(file, key: string) {
    if (file) {
      const reader = new FileReader();
      reader.onload = ((e) => {
        this.imageSrcMap.set(key, this.domSanitizer.bypassSecurityTrustUrl(<string>e.target['result']));
      });
      reader.readAsDataURL(file);
    }
  }
*/

  ngAfterViewInit(): void {
    this.checkElementHeight();
  }


  /* Evaluate this row height, and report to parent table
  *  The parent table will then check its own height
  *  If the parent table height exceeds a specified value, a new table will be created to hold this row
  */
  checkElementHeight() {
    const div = this.rowDiv.nativeElement as HTMLDivElement;
    this.rowHeight = div.offsetHeight;
    this.tableRowOut.emit({ index: this.rowIndex, height: this.rowHeight });
  }

}
