<div class="p-grid table-demo">
	<div class="p-col-12">
		<div class="card" style="min-height: 75vmin;">

			<h5>{{labels.Organizations}}</h5>
			<p-confirmDialog header="Confirmation" key="deleteConfirm" icon="pi pi-exclamation-triangle"
                             [style]="{width: '425px'}" acceptButtonStyleClass="p-button-text" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
			<p-toolbar styleClass="p-mb-4">
                <ng-template pTemplate="left">
                    <button pButton pRipple [label]="labels.New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" (click)="onAdd()"></button>
                    <button pButton type="button" [label]="labels.Update" icon="pi pi-pencil" class="p-button-info" (click)="updateSelectedItem()" [disabled]="!selectedOrganization"></button>
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple [label]="labels.Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" (click)="deleteSelectedItem()" [disabled]="!selectedOrganization"></button>
                </ng-template>
            </p-toolbar>
			
			<p-table #dtc [value]="organizations" [(selection)]="selectedOrganization" dataKey="id" selectionMode="single"
					 styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
                     [scrollable]="true" scrollHeight="55vmin" [filterDelay]="0" 
                     [globalFilterFields]="['name','type','state.name']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						<span></span>
						<span class="p-input-icon-left p-float-label">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtc.filterGlobal($event.target.value, 'contains')" />
                            <label class="global-filter-label">{{labels.Search}}</label>
                        </span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
                        <th pSortableColumn="name" style="background-color: lightgray;">{{labels.Name}}
							<p-sortIcon field="name"></p-sortIcon>
						</th>
						<th pSortableColumn="type" style="background-color: lightgray;">{{labels.Type}}
							<p-sortIcon field="type"></p-sortIcon>
						</th>
						<th pSortableColumn="stateName" style="background-color: lightgray;">{{labels.StateName}}
							<p-sortIcon field="stateName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="address" style="background-color: lightgray;">{{labels.Address}}
							<p-sortIcon field="address"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email" style="background-color: lightgray;">{{labels.Email}}
							<p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="website" style="background-color: lightgray;">{{labels.Website}}
							<p-sortIcon field="website"></p-sortIcon>
                        </th>
                        <th pSortableColumn="phone" style="background-color: lightgray;">{{labels.Phone}}
							<p-sortIcon field="phone"></p-sortIcon>
                        </th>
                        <th pSortableColumn="fax" style="background-color: lightgray;">{{labels.Fax}}
							<p-sortIcon field="fax"></p-sortIcon>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-organization>
					<tr [pSelectableRow]="organization" style="height: 50px; word-wrap: break-word;">
                        <td>
							<span class="p-column-title">{{labels.Name}}</span>{{organization.name}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Type}}</span>{{itemLabel(organization.type)}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.StateName}}</span>{{organization.state ? organization.state.name : 'none'}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Address}}</span>{{organization.address}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Email}}</span>{{organization.email}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Website}}</span>
							<a *ngIf="organization.website?.includes('http')" target="_blank" href="{{organization.website}}">{{organization.website}}</a>
							<span *ngIf="!organization.website?.includes('http')">{{organization.website}}</span>
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Phone}}</span>{{organization.phone}}
							
                        </td>
                        <td>
							<span class="p-column-title">{{labels.Fax}}</span>{{organization.fax}}
							
                        </td>					
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">{{labels.Nodata}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
    </div>
</div>