<div class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <h5>{{labels.AddOrganization}}</h5>
            <div>
                <p style="height: 20px;"></p> 
            </div>
            <form [formGroup]="mainForm" (ngSubmit)="onSave()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="name" type="text" pInputText formControlName="name">
                            <label for="name">{{labels.Name}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <p-dropdown id="type" [options]="organizationTypes"
                                formControlName="type" (onChange)="onTypeChange()">
                            </p-dropdown>
                            <label for="type">{{labels.Type}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label" *ngIf="showStates">
                            <p-dropdown id="state" [options]="statesItems"
                                formControlName="state">
                            </p-dropdown>
                            <label for="officialLanguage">{{labels.State}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <textarea id="address" type="text" rows="6" pInputText formControlName="address"></textarea>
                            <label for="address">{{labels.Address}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="email" type="text" pInputText formControlName="email">
                            <label for="email">{{labels.Email}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="website" type="text" pInputText formControlName="website">
                            <label for="website">{{labels.Website}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="phone" type="text" pInputText formControlName="phone">
                            <label for="phone">{{labels.Phone}}</label>
                        </span>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <span class="p-float-label">
                            <input id="fax" type="text" pInputText formControlName="fax">
                            <label for="fax">{{labels.Fax}}</label>
                        </span>
                    </div>                    
                </div>
                <div>
                    <p style="height: 50px;"></p>
                    <hr>
                </div>
                <div align="right">
                    <button pButton pRipple type="button" label="{{labels.Cancel}}" (click)="onCancel()"
                        icon="pi pi-times" class="p-button-raised p-button-warning p-mr-2 p-mb-2"></button>
                    <button pButton pRipple type="submit" label="{{labels.Save}}" [disabled]="!mainForm.valid"
                        icon="pi pi-check" class="p-button-raised p-mr-2 p-mb-2"></button>
                </div>
            </form>
        </div>
    </div>
</div>